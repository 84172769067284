import {Booking, MeetingRoomBooking} from "../../../API";
import React, {useCallback} from "react";
import {gql, useMutation} from "@apollo/client";
import {MeetingRoomBookings} from "../../../types/MeetingRoomBookingType";
import {SeatPaper} from "./SeatPaper";
import {MeetingRoomPaper} from "./MeetingRoomPaper";
import {ExecuteOtherBookingsButton} from "./ExecuteOtherBookingsButton";
import {BookingType} from "../../../types/BookingType";
import {useTranslation} from "react-i18next";
import {deleteSecureMeetingRoomBooking} from "../../../graphql/mutations";

interface Props {
    position: React.CSSProperties
    offset: number
    isTimeActive: boolean
    deletableSeatBookings: Booking[]
    deletableMRBookings: MeetingRoomBooking[]
    handleBookOtherDateTime: () => void
    closeDeleteBookingDialog: () => void
    bookingType: BookingType,
    meetingRoomBookingProps?: MeetingRoomBookings | null
}

// TODO move somewhere else
export type IBooking = Booking | MeetingRoomBooking | undefined


const ItemClickedDialog: React.FC<Props> = (props) => {

    const {
        position,
        offset,
        isTimeActive,
        handleBookOtherDateTime,
        closeDeleteBookingDialog,
        bookingType,
        meetingRoomBookingProps,
        deletableSeatBookings,
        deletableMRBookings
    } = props;

    const [deleteMeetingRoomBookingMutation] = useMutation(gql(deleteSecureMeetingRoomBooking));

    const removeSelectedMRBooking = useCallback((meetingRoomBooking: MeetingRoomBooking) => {
        deleteMeetingRoomBookingMutation({
            variables: {
                input: {
                    bookingId: meetingRoomBooking.bookingId,
                    bookerId: meetingRoomBooking.bookerId,
                    orgUnitId: meetingRoomBooking.orgUnitId,
                    meetingRoomId: meetingRoomBooking.meetingRoomId,
                    roomId: meetingRoomBooking.roomId,
                    date: meetingRoomBooking.date
                }
            }
        }).catch((err) => {
            console.error("ItemClickedDialog removeSelectedMeetRoomBooking:", err);
        }).finally(closeDeleteBookingDialog)
    }, [closeDeleteBookingDialog, deleteMeetingRoomBookingMutation])


    const {t} = useTranslation();
    const getExecuteOtherBookingsButtonText = function () {
        const nameBasedOnTimeActivityForSeatPopup = isTimeActive ? t("button_book_other_days_times") : t("button_book_other_days");
        return bookingType === "meetingRoom" ? t("open_another_booking_dialog") : nameBasedOnTimeActivityForSeatPopup;
    }

    const executeOtherBookingsButton = <ExecuteOtherBookingsButton handleBookOtherDateTime={handleBookOtherDateTime}
                                                                   text={getExecuteOtherBookingsButtonText()}/>

    return (
        <>
            {(bookingType === "meetingRoom") ?
                <MeetingRoomPaper
                    deletableBookings={deletableMRBookings}
                    executeOtherBookingsButton={executeOtherBookingsButton}
                    position={position}
                    offset={offset}
                    meetingRoomBookingProps={meetingRoomBookingProps}
                    removeSelectedBooking={removeSelectedMRBooking}
                />
                :
                <SeatPaper
                    deletableBookings={deletableSeatBookings}
                    executeOtherBookingsButton={executeOtherBookingsButton}
                    position={position}
                    offset={offset}
                    isTimeActive={isTimeActive}
                    closeDeleteBookingDialog={closeDeleteBookingDialog}
                />
            }
        </>);
}

export default ItemClickedDialog





