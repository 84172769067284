import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Booking} from "../../../API";
import Paper from "@mui/material/Paper";
import {formatTimeRange} from "../../../Utils/dateAndTime";
import {compareBookingsByTime} from "../../../Utils/Comparison";
import {EditAndDeleteListItem, Props as EditAndDeleteProps} from "../BookingsPopup/BookingEditAndDeleteListItem";
import {SeatBookingEditDialog} from "../../EditSeatBookingDialog/SeatBookingEditDialog";
import {
    EditSeatBookingContextType,
    EditSeatBookingFromPlanContext
} from "../../EditSeatBookingDialog/EditSeatBookingFromPlanContext";
import {Stack} from "@mui/material";
import {BookingDeletionConfirmationDialog} from "../../ConfirmationDialogs/BookingDeletionConfirmationDialog";
import {
    ConfirmDeleteSeatBookingContextType,
    ConfirmSeatBookingDeletionFromPlanContext
} from "../Contexts/ConfirmSeatBookingDeletionFromPlanContext";
import {useDeleteSeatBooking} from "../../../hooks/bookings/useDeleteSeatBooking";
import {useErrorContext} from "../../../hooks/useErrorContext";


interface SeatPaperProps {
    executeOtherBookingsButton: JSX.Element
    position: React.CSSProperties,
    offset: number,
    isTimeActive: boolean,
    deletableBookings: Booking[], // TODO rename
    closeDeleteBookingDialog : () => void
}

export const SeatPaper: React.FC<SeatPaperProps> = (props) => {
    const {
        executeOtherBookingsButton,
        position,
        offset,
        isTimeActive,
        deletableBookings,
        closeDeleteBookingDialog
    } = props;

    const [elementPositionX, setElementPositionX] = useState(position.left);
    const [elementPositionY, setElementPositionY] = useState(position.top);

    const {reportError} = useErrorContext();
    const deleteSeatBooking = useDeleteSeatBooking();

    const {
        isSeatBookingDeletionConfirmationDialogOpen,
        setIsSeatBookingDeletionConfirmationOpen,
        setSeatBookingToDelete,
        seatBookingToDelete
    } = useContext(ConfirmSeatBookingDeletionFromPlanContext) as ConfirmDeleteSeatBookingContextType;
    const {
        isBookingEditDialogOpen,
        setIsBookingEditDialogOpen,
        bookingToEdit,
        setSeatBookingToEdit
    } = useContext(EditSeatBookingFromPlanContext) as EditSeatBookingContextType;
    const otherBookingsSameSeat = useMemo(() => {
        return deletableBookings.filter(b => b.bookingId !== bookingToEdit?.bookingId)
    }, [bookingToEdit, deletableBookings])

    const elementRef = useRef<HTMLDivElement>(null);

    const itemPropsList: (EditAndDeleteProps & {key: string})[] = useMemo(() => {
        const sortedSeatBookings = [...deletableBookings].sort(compareBookingsByTime);
        return sortedSeatBookings.map(booking => {
            return {
                key: booking.bookingId,
                displayText: formatTimeRange(booking.timeBegin, booking.timeEnd),
                onEditButtonPressed: () => {
                    setSeatBookingToEdit(booking);
                    setIsBookingEditDialogOpen(true);
                },
                onDeleteButtonPressed: () => {
                    setSeatBookingToDelete(booking);
                    setIsSeatBookingDeletionConfirmationOpen(true);
                }
            }
        });
    }, [setSeatBookingToDelete, setIsSeatBookingDeletionConfirmationOpen, deletableBookings, setIsBookingEditDialogOpen, setSeatBookingToEdit])

    useEffect(() => {
        if (elementRef.current && position.left && position.top) {
            if (window.innerWidth - offset < parseInt(position.left as string) + elementRef.current.offsetWidth) {
                setElementPositionX(window.innerWidth - elementRef.current.offsetWidth - offset);
            }
            if (window.innerHeight - offset < parseInt(position.top as string) + elementRef.current.offsetHeight) {
                setElementPositionY(window.innerHeight - elementRef.current.offsetHeight - offset);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef.current])

    function onCancelSeatBookingDeletion() {
        setIsSeatBookingDeletionConfirmationOpen(false);
        setSeatBookingToDelete(null);
    }

    function onConfirmSeatBookingDeletion() {
        setIsSeatBookingDeletionConfirmationOpen(false);
        if (seatBookingToDelete === null) {
            return;
        }
        deleteSeatBooking(seatBookingToDelete)
            .catch(e => {
                // TODO translate error message
                reportError(e, "Error deleting seat booking", "SeatPaper onConfirmSeatBookingDeletion")
            }).finally( ()=> closeDeleteBookingDialog());
    }

    return <Paper ref={elementRef} elevation={3} className={"SeatAlreadyBookedHint"} data-testid={"already-booked"}
                  sx={{
                      left: elementPositionX,
                      top: elementPositionY,
                      whiteSpace: "nowrap"
                  }}>
        {executeOtherBookingsButton}
        <Stack
            direction="column"
            spacing={0.5}
            paddingTop={0.5}
            paddingBottom={0.5}
        >
            {itemPropsList.map(props => {
                const {key, ...rest} = props;
                return <EditAndDeleteListItem key={key} {...rest} />;
            })}
        </Stack>
        <SeatBookingEditDialog
            otherBookingsForTimeWindow={otherBookingsSameSeat}
            isTimeBookingActive={isTimeActive}
            isOpen={isBookingEditDialogOpen}
            handleClose={() => {
                setIsBookingEditDialogOpen(false);
                setSeatBookingToEdit(null);
            }}
            bookingToEdit={bookingToEdit}/>
        <BookingDeletionConfirmationDialog
            isOpen={isSeatBookingDeletionConfirmationDialogOpen && seatBookingToDelete !== null}
            onCancel={onCancelSeatBookingDeletion}
            onConfirm={onConfirmSeatBookingDeletion}/>
    </Paper>
}
